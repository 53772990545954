var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "900px", height: "600px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.SETT010T010.036"))),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("form", { attrs: { id: "frm" } }, [
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("msg.SETT010T010.037"))),
            ]),
            _c("table", { staticClass: "tbl_row" }, [
              _vm._m(0),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.038")))]),
                  _c("td", [_vm._v(_vm._s(_vm.invoiceInfo.bkgNo))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.039")))]),
                  _c("td", [_vm._v(_vm._s(_vm.invoiceInfo.blNo))]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.040")))]),
                  _c("td", [_vm._v(_vm._s(_vm.invoiceInfo.polPlcNm))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.041")))]),
                  _c("td", [_vm._v(_vm._s(_vm.invoiceInfo.podPlcNm))]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.042")))]),
                  _c("td", [_vm._v(_vm._s(_vm.invoiceInfo.vslNm))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.043")))]),
                  _c("td", [_vm._v(_vm._s(_vm.invoiceInfo.voyNo))]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.044")))]),
                  _c("td", { attrs: { colspan: "3" } }, [
                    _vm._v(_vm._s(_vm.invoiceInfo.actShprCstEnm)),
                  ]),
                ]),
              ]),
            ]),
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("msg.SETT010T010.045"))),
            ]),
            _c("table", { staticClass: "tbl_col mt5" }, [
              _vm._m(1),
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.046")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.047")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.048")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.049")))]),
                ]),
              ]),
              _c("tbody", [
                _c("tr", [
                  _c("td", { staticClass: "text_left" }, [
                    _vm._v(_vm._s(_vm.invoiceInfo.userInfo.compEname)),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", { staticClass: "tbl_form" }, [
                      _c("span", { staticClass: "dv col_4" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.picItem,
                                expression: "picItem",
                              },
                            ],
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.picItem = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                _vm.changePic,
                              ],
                            },
                          },
                          [
                            _c(
                              "option",
                              { attrs: { value: "0", selected: "selected" } },
                              [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.050")))]
                            ),
                            _vm._l(_vm.cstList, function (cvo, i) {
                              return _c(
                                "option",
                                { key: cvo.picNo, domProps: { value: i + 1 } },
                                [_vm._v(_vm._s(cvo.picNm))]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                      _c("span", { staticClass: "dv" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.params.reqPicNm,
                              expression: "params.reqPicNm",
                            },
                          ],
                          attrs: {
                            type: "text",
                            id: "reqPicNm",
                            readonly: _vm.params.readYn === "Y",
                          },
                          domProps: { value: _vm.params.reqPicNm },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.params,
                                "reqPicNm",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.reqPicTelNo,
                          expression: "params.reqPicTelNo",
                        },
                      ],
                      attrs: {
                        type: "text",
                        id: "reqPicTelNo",
                        readonly: _vm.params.readYn === "Y",
                      },
                      domProps: { value: _vm.params.reqPicTelNo },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.params,
                            "reqPicTelNo",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.reqPicEmlAddr,
                          expression: "params.reqPicEmlAddr",
                        },
                      ],
                      attrs: {
                        type: "text",
                        id: "reqPicEmlAddr",
                        readonly: _vm.params.readYn === "Y",
                      },
                      domProps: { value: _vm.params.reqPicEmlAddr },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.params,
                            "reqPicEmlAddr",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
            _c("table", { staticClass: "tbl_row mt10" }, [
              _vm._m(2),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.051")))]),
                  _c("td", [
                    _c("input", {
                      attrs: {
                        type: "radio",
                        id: "fare1",
                        name: "fare",
                        checked: "checked",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.radioClick("01")
                        },
                      },
                    }),
                    _c(
                      "label",
                      { staticClass: "mr20", attrs: { for: "fare1" } },
                      [
                        _c("span"),
                        _vm._v(_vm._s(_vm.$t("msg.SETT010T010.052"))),
                      ]
                    ),
                    _c("input", {
                      attrs: { type: "radio", id: "fare2", name: "fare" },
                      on: {
                        click: function ($event) {
                          return _vm.radioClick("02")
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "fare2" } }, [
                      _c("span"),
                      _vm._v(_vm._s(_vm.$t("msg.SETT010T010.053"))),
                    ]),
                    _c("div", { staticClass: "mt10" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.params.reqCont,
                            expression: "params.reqCont",
                          },
                        ],
                        attrs: { id: "reqCont" },
                        domProps: { value: _vm.params.reqCont },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.params, "reqCont", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("msg.SETT010T010.054"))),
            ]),
            _c("div", [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.params.reqRsnCont,
                    expression: "params.reqRsnCont",
                  },
                ],
                attrs: { id: "reqRsnCont" },
                domProps: { value: _vm.params.reqRsnCont },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.params, "reqRsnCont", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            { staticClass: "button blue lg", on: { click: _vm.sendRequest } },
            [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.055")))]
          ),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.SETT010T010.056")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "30%" } }),
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "25%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "130" } }), _c("col")])
  },
]
render._withStripped = true

export { render, staticRenderFns }