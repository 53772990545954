<template>
  <div class="popup_wrap" style="width:900px;height:600px;">
    <!-- popup_wrap : style="width:280px height:550px" 참고용, 개발시 제거 -->
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->

      <h1 class="page_title">{{ $t('msg.SETT010T010.036') }}</h1>
      <div class="content_box">
        <!-- content_box -->
        <form id="frm">
          <h2 class="content_title">{{ $t('msg.SETT010T010.037') }}</h2>
          <table class="tbl_row">
            <colgroup>
              <col width="130"><col><col width="130"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.SETT010T010.038') }}</th>
                <td>{{ invoiceInfo.bkgNo }}</td>
                <th>{{ $t('msg.SETT010T010.039') }}</th>
                <td>{{ invoiceInfo.blNo }}</td>
              </tr>
              <tr>
                <th>{{ $t('msg.SETT010T010.040') }}</th>
                <td>{{ invoiceInfo.polPlcNm }}</td>
                <th>{{ $t('msg.SETT010T010.041') }}</th>
                <td>{{ invoiceInfo.podPlcNm }}</td>
              </tr>
              <tr>
                <th>{{ $t('msg.SETT010T010.042') }}</th>
                <td>{{ invoiceInfo.vslNm }}</td>
                <th>{{ $t('msg.SETT010T010.043') }}</th>
                <td>{{ invoiceInfo.voyNo }}</td>
              </tr>
              <tr>
                <th>{{ $t('msg.SETT010T010.044') }}</th>
                <td colspan="3">{{ invoiceInfo.actShprCstEnm }}</td>
              </tr>
            </tbody>
          </table>

          <h2 class="content_title">{{ $t('msg.SETT010T010.045') }}</h2>
          <table class="tbl_col mt5">
            <colgroup>
              <col width="30%">
              <col width="25%">
              <col width="20%">
              <col width="25%">
            </colgroup>
            <thead>
              <tr>
                <th>{{ $t('msg.SETT010T010.046') }}</th>
                <th>{{ $t('msg.SETT010T010.047') }}</th>
                <th>{{ $t('msg.SETT010T010.048') }}</th>
                <th>{{ $t('msg.SETT010T010.049') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text_left">{{ invoiceInfo.userInfo.compEname }}</td> <!-- 접속한 사용자 영문 회사명 -->
                <td>
                  <div class="tbl_form">
                    <span class="dv col_4">
                      <select v-model="picItem" @change="changePic">
                        <option value="0" selected="selected">{{ $t('msg.SETT010T010.050') }}</option>
                        <option v-for="(cvo, i) in cstList" :key="cvo.picNo" :value="i+1">{{ cvo.picNm }}</option>
                      </select>
                    </span>
                    <span class="dv">
                      <input type="text" id="reqPicNm" v-model="params.reqPicNm" :readonly="params.readYn === 'Y'">
                    </span>
                  </div>
                </td>
                <td><input type="text" id="reqPicTelNo" v-model="params.reqPicTelNo" :readonly="params.readYn === 'Y'"></td>
                <td><input type="text" id="reqPicEmlAddr" v-model="params.reqPicEmlAddr" :readonly="params.readYn === 'Y'"></td>
              </tr>
            </tbody>
          </table>

          <table class="tbl_row mt10">
            <colgroup>
              <col width="130"><col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.SETT010T010.051') }}</th>
                <td>
                  <input type="radio" id="fare1" name="fare" checked="checked" @click="radioClick('01')">
                  <label for="fare1" class="mr20"><span></span>{{ $t('msg.SETT010T010.052') }}</label>
                  <input type="radio" id="fare2" name="fare" @click="radioClick('02')">
                  <label for="fare2"><span></span>{{ $t('msg.SETT010T010.053') }}</label>
                  <div class="mt10">
                    <textarea id="reqCont" v-model="params.reqCont"></textarea>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <h2 class="content_title">{{ $t('msg.SETT010T010.054') }}</h2>
          <div>
            <textarea id="reqRsnCont" v-model="params.reqRsnCont"></textarea>
          </div>
        </form>
      </div><!-- content_box // -->

      <div class="mt10 text_center">
        <a class="button blue lg" @click="sendRequest">{{ $t('msg.SETT010T010.055') }}</a><a class="button gray lg" @click="$emit('close')">{{ $t('msg.SETT010T010.056') }}</a>
      </div>
    </div><!-- popup_cont -->
  </div><!-- popup_wrap // -->
</template>

<script>
import CalcIssue from '@/api/rest/settle/calcIssue'

export default {
    name: 'CalcIssueFreightPop',
    components: {},
    props: {
      detail: {
        type: Object,
          default: function () {
          return {
            remarkCd: '',
            blNo: ''
          }
        }
      },
      isShowPop: {
        type: Boolean,
        default: false
      }
    },
    data: function () {
      return {
        idx: 0,
        total: 0,
        delegChk: '',
        picItem: '',
        params: {
          blNo: '',
          bkgNo: '',
          reqPicNm: '',
          reqPicTelNo: '',
          reqPicEmlAddr: '',
          reqCont: '',
          reqRsnCont: '',
          rmk: '01',
          picNo: '',
          readYn: 'Y'
        },
        invoiceInfo: {
          userInfo: {}
        },
        cstList: []
      }
    },
    watch: {
      isShowPop () {
        this.showData()
        //console.log(this.isShowPop)
      }
    },
    created: function () {
      //window.functionOutsideVue(this)
      //console.log('created')
    },
    mounted: function () {
      this.showData()
    },
    methods: {
        showData () {
          if (this.isShowPop === true) {
            this.params.blNo = this.detail.blNo
            this.selectFreight(this.params)
          }
        },
        sendRequest () {
          let isAllOk = this.checkReqInfo()

          if (isAllOk.isOk === false) {
            this.$ekmtcCommon.showErrorTooltip(isAllOk.elem, isAllOk.msg)
          } else {
            this.params = { ...this.params, ...this.detail, ...this.invoiceInfo }
            console.log('sendRequest')
            console.log(this.params)
            CalcIssue.insertReq(this.params)
              .then(res => {
                  // this.openAlert('저장하였습니다.')
                  this.openAlert(this.$t('msg.SETT010T010.111'))
                  this.$emit('close')
              })
          }
        },
        async selectFreight (param) {
          await CalcIssue.getFreightInfo(param)
            .then(response => {
              console.log(response)
              const resp = response.data
              //this.total = resp.length
              this.invoiceInfo = resp.reqVo
              this.invoiceInfo.userInfo = resp.userInfo
              this.cstList = resp.cstList
              this.delegChk = resp.delegChk
              if (resp.frtReqYn === 'Y') {
                this.openAlert(this.$t('msg.DOIssue.015'))
                this.$emit('close')
              }
              console.log(resp)
            })
            .catch(err => {
              console.log(err)
            })
        },
        checkReqInfo () {
          const frm = document.querySelector('#frm')
          var elem = ''

          let isOk = true
          let msg = ''

          if (this.params.reqPicNm === '' && this.params.reqPicTelNo === '' && this.params.reqPicEmlAddr === '') {
            if (this.params.reqPicNm === '') {
              msg = 'Please Input request charge name.'
              elem = frm.querySelector('#reqPicNm')
              isOk = false
            } else {
              this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#reqPicNm'))
            }
            if (this.params.reqPicTelNo === '') {
              msg = 'Please Input request charge tel number.'
              elem = frm.querySelector('#reqPicTelNo')
              isOk = false
            } else {
              this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#reqPicTelNo'))
            }
            if (this.params.reqPicEmlAddr === '') {
              msg = 'Please Input request charge e-mail.'
              elem = frm.querySelector('#reqPicEmlAddr')
              isOk = false
            } else {
              this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#reqPicEmlAddr'))
            }
          }
          if (this.params.reqCont === '') {
            // msg = 'Please Input request contents.'
            msg = this.$t('msg.SETT010T010.110')
            elem = frm.querySelector('#reqCont')
            isOk = false
          } else {
            this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#reqCont'))
          }
          if (this.params.reqRsnCont === '') {
            // msg = 'Please Input request reason contents.'
            msg = this.$t('msg.SETT010T010.109')
            elem = frm.querySelector('#reqRsnCont')
            isOk = false
          } else {
            this.$ekmtcCommon.hideErrorTooltip(frm.querySelector('#reqRsnCont'))
          }

          let res = {
            msg: msg,
            elem: elem,
            isOk: isOk
          }

          return res
        },
        openAlert (msg) {
          let obj = {
            alertType: 'simple',
            onClose: this.closeFun,
            customCloseBtnClass: 'button blue lg simplert-test1',
            customCloseBtnText: this.$t('msg.CSBK100.137'),
            useIcon: false,
            customClass: 'simple_alert',
            message: msg,
            type: 'info'
          }
          this.$ekmtcCommon.alert(obj)
        },
        closeFun () {
          this.$Simplert.close()
        },
        changePic (e) {
          this.idx = e.target.value
          console.log('changePic')
          console.log(this.idx)
          console.log(this.cstList[this.idx])
          if (this.idx > 0) {
            if (this.$ekmtcCommon.isEmpty(this.cstList[this.idx - 1].picNm)) {
              this.cstList[this.idx - 1].picNm = ''
            }
            if (this.$ekmtcCommon.isEmpty(this.cstList[this.idx - 1].telNo)) {
              this.cstList[this.idx - 1].telNo = ''
            }
            if (this.$ekmtcCommon.isEmpty(this.cstList[this.idx - 1].telPlcNo)) {
              this.cstList[this.idx - 1].telPlcNo = ''
            }
            if (this.$ekmtcCommon.isEmpty(this.cstList[this.idx - 1].telOfcNo)) {
              this.cstList[this.idx - 1].telOfcNo = ''
            }
            if (this.$ekmtcCommon.isEmpty(this.cstList[this.idx - 1].emlAddr)) {
              this.cstList[this.idx - 1].emlAddr = ''
            }
            this.params.readYn = 'Y'
            this.params.reqPicNm = this.cstList[this.idx - 1].picNm
            this.params.reqPicTelNo = this.cstList[this.idx - 1].telPlcNo + this.cstList[this.idx - 1].telOfcNo + this.cstList[this.idx - 1].telNo
            this.params.reqPicEmlAddr = this.cstList[this.idx - 1].emlAddr
          } else {
            this.params.readYn = 'N'
            this.params.reqPicNm = ''
            this.params.reqPicTelNo = ''
            this.params.reqPicEmlAddr = ''
          }
        },
        radioClick (v) {
          this.params.rmk = v
        }
    }
}

</script>

<style scoped>
a.no-uline:hover{
  text-decoration:underline
}
</style>
